import React from 'react';

import { Container } from '../components/layout/Container';
import { Display1 } from '../components/shared/Display';

import ContactForm from '../components/features/ContactForm';
import { Heading } from '../components/shared/Heading';
import { Section } from '../components/shared/Section';
import { SectionHeader } from '../components/shared/SectionHeader';

export default function Contact() {
    return (
        <>
            <SectionHeader bg="https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/35114-13th-pl-sw/DJI_0078-min_czwzwx.webp">
                <Display1>Contact Us</Display1>
            </SectionHeader>
            <Container>
                <Section>
                    <Heading>
                        <h2>Send Us a Message</h2>
                    </Heading>
                    <ContactForm /> 
                </Section>
            </Container>
        </>
    );
}