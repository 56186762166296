import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../shared/Button';
import { Card, CardIcon, CardHeading, CardPricing, CardBody } from '../shared/Card';
import { Grid } from '../layout/Grid';

import { ReactComponent as DroneIcon } from '../../assets/icons/drone-icon.svg';
import { ReactComponent as VideoIcon } from '../../assets/icons/video-icon.svg';

export default function VideographyPricing() {
    return (
        <Grid cols={3} gap={1}>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><DroneIcon /></CardIcon>
                <CardHeading>Drone Video</CardHeading>
                <CardPricing>$250</CardPricing>
                <CardBody>
                    <ul>
                        <li>High quality aerial video taken with a high-end drone</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><VideoIcon /></CardIcon>
                <CardHeading>Professional Video</CardHeading>
                <CardPricing>$300</CardPricing>
                <CardBody>
                    <ul>
                        <li>With purchase of photos</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><VideoIcon /></CardIcon>
                <CardHeading>Professional Video</CardHeading>
                <CardPricing>$400</CardPricing>
                <CardBody>
                    <ul>
                        <li>Without purchase of photos</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
        </Grid>
    );
}