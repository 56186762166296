import React from 'react';

import { Footer, FooterCopyright, FooterNav, FooterNavItem, FooterCertifications } from '../shared/Footer';
import { TextLink } from '../shared/TextLink';

export default function CustomFooter() {
    return (
       <Footer>
            <FooterNav>
                <FooterNavItem>
                    <TextLink to="/">Home</TextLink>
                </FooterNavItem>
                <FooterNavItem>
                    <TextLink to="/services">Services</TextLink>
                </FooterNavItem>
                <FooterNavItem>
                    <TextLink to="/gallery">Gallery</TextLink>
                </FooterNavItem>
                <FooterNavItem>
                    <TextLink to="/contact">Contact</TextLink>
                </FooterNavItem>
            </FooterNav>
            <FooterCopyright>
                <p>&copy; TNT Visual Productions 2021</p>
            </FooterCopyright>
            <FooterCertifications>
                <img src="https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/nwmls-logo-min_h8g1sl.png" alt="NWMLS Logo" />
                <img src="https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/faa-logo-min_fsl6fd.png" alt="FAA Logo" />
            </FooterCertifications>
       </Footer> 
    );
}