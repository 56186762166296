import styled from 'styled-components';

export const Display1 = styled.h1`
    font-size: 3rem;
    text-transform: uppercase;

    @media (min-width: 48rem) {
        font-size: 4rem;
    }   
`;

export const Display2 = styled.h1`
    font-size: 2.5rem;
    text-transform: uppercase;
    
    @media (min-width: 48rem) {
        font-size: 3.5rem;
    }
`;