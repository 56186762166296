import React, { useState, useEffect } from 'react';

import { Grid } from '../layout/Grid';

import ImageModal from '../shared/ImageModal';

export default function PhotoGallery() {
    const [photos, setPhotos] = useState(null);

    const photoArr = [
        {id: 1, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/2301-n-winnifred-st/DJI_0127-min_ba6bep.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/2301-n-winnifred-st/DJI_0127-min_ba6bep.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/2301-n-winnifred-st/DJI_0127-min_ba6bep.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/2301-n-winnifred-st/DJI_0127-min_ba6bep.webp 1440w"},
        {id: 2, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/2301-n-winnifred-st/DJI_0138-min_icpvus.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/2301-n-winnifred-st/DJI_0138-min_icpvus.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/2301-n-winnifred-st/DJI_0138-min_icpvus.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/2301-n-winnifred-st/DJI_0138-min_icpvus.webp 1440w"},
        {id: 3, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/2301-n-winnifred-st/DJI_0142-min_idmy9b.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/2301-n-winnifred-st/DJI_0142-min_idmy9b.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/2301-n-winnifred-st/DJI_0142-min_idmy9b.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/2301-n-winnifred-st/DJI_0142-min_idmy9b.webp 1440w"},
        {id: 4, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/15128-276th-pl-ne/DJI_0169-min_wvfz8m.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/15128-276th-pl-ne/DJI_0169-min_wvfz8m.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/15128-276th-pl-ne/DJI_0169-min_wvfz8m.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/15128-276th-pl-ne/DJI_0169-min_wvfz8m.webp 1440w"},
        {id: 5, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/35114-13th-pl-sw/DJI_0078-min_czwzwx.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/35114-13th-pl-sw/DJI_0078-min_czwzwx.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/35114-13th-pl-sw/DJI_0078-min_czwzwx.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/35114-13th-pl-sw/DJI_0078-min_czwzwx.webp 1440w"},
        {id: 6, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/35114-13th-pl-sw/DJI_0086-min_ejb0ao.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/35114-13th-pl-sw/DJI_0086-min_ejb0ao.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/35114-13th-pl-sw/DJI_0086-min_ejb0ao.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/35114-13th-pl-sw/DJI_0086-min_ejb0ao.webp 1440w"},
        {id: 7, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/14013-greenwood-ave-n/DSC05258-HDR-min_nhu3gl.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/14013-greenwood-ave-n/DSC05258-HDR-min_nhu3gl.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/14013-greenwood-ave-n/DSC05258-HDR-min_nhu3gl.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/14013-greenwood-ave-n/DSC05258-HDR-min_nhu3gl.webp 1440w"},
        {id: 8, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/14013-greenwood-ave-n/DSC05279-HDR-min_t9exjc.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/14013-greenwood-ave-n/DSC05279-HDR-min_t9exjc.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/14013-greenwood-ave-n/DSC05279-HDR-min_t9exjc.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/14013-greenwood-ave-n/DSC05279-HDR-min_t9exjc.webp 1440w"},
        {id: 9, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/14013-greenwood-ave-n/DSC05262-HDR-min_iyggym.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/14013-greenwood-ave-n/DSC05262-HDR-min_iyggym.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/14013-greenwood-ave-n/DSC05262-HDR-min_iyggym.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/14013-greenwood-ave-n/DSC05262-HDR-min_iyggym.webp 1440w"},
        {id: 10, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/14013-greenwood-ave-n/DSC05327-HDR-min_aumira.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/14013-greenwood-ave-n/DSC05327-HDR-min_aumira.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/14013-greenwood-ave-n/DSC05327-HDR-min_aumira.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/14013-greenwood-ave-n/DSC05327-HDR-min_aumira.webp 1440w"},
        {id: 11, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/14013-greenwood-ave-n/DSC05359-HDR-min_we7ofq.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/14013-greenwood-ave-n/DSC05359-HDR-min_we7ofq.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/14013-greenwood-ave-n/DSC05359-HDR-min_we7ofq.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/14013-greenwood-ave-n/DSC05359-HDR-min_we7ofq.webp 1440w"},
        {id: 12, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/2301-n-winnifred-st/DSC05685-HDR-min_axf7qt.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/2301-n-winnifred-st/DSC05685-HDR-min_axf7qt.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/2301-n-winnifred-st/DSC05685-HDR-min_axf7qt.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/2301-n-winnifred-st/DSC05685-HDR-min_axf7qt.webp 1440w"},
        {id: 13, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/2301-n-winnifred-st/DSC05734-HDR-min_q0rbpg.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/2301-n-winnifred-st/DSC05734-HDR-min_q0rbpg.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/2301-n-winnifred-st/DSC05734-HDR-min_q0rbpg.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/2301-n-winnifred-st/DSC05734-HDR-min_q0rbpg.webp 1440w"},
        {id: 14, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/15128-276th-pl-ne/DSC05811-HDR-min_lknnsj.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/15128-276th-pl-ne/DSC05811-HDR-min_lknnsj.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/15128-276th-pl-ne/DSC05811-HDR-min_lknnsj.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/15128-276th-pl-ne/DSC05811-HDR-min_lknnsj.webp 1440w"},
        {id: 15, src: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_auto,dpr_auto/15128-276th-pl-ne/DSC05800-min_resbdt.webp", srcSetSm: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_828,dpr_auto/15128-276th-pl-ne/DSC05800-min_resbdt.webp 414w", srcSetMd: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_1536,dpr_auto/15128-276th-pl-ne/DSC05800-min_resbdt.webp 768w", srcSetLg: "https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/15128-276th-pl-ne/DSC05800-min_resbdt.webp 1440w"},
        
    ];

    useEffect(() => {
        setPhotos(photoArr.map(photo => {
            return (
                <ImageModal src={photo.src} srcSetSm={photo.srcSetSm} srcSetMd={photo.srcSetMd} srcSetLg={photo.srcSetLg} alt="Gallery Photo" />
            );
        }));
    });

    return (
        <Grid cols={3} gap={1}>
            {photos}
        </Grid>
    );
}