import styled from 'styled-components';

export const SectionHeader = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url("${props => props.bg}") no-repeat center center;
    -webkit-background-size: cover; 
    -moz-background-size: cover; 
    -o-background-size: cover; 
    background-size: cover; 
    overflow: hidden;
    color: var(--dark);

    & > * {
        z-index: 10;
    }

    @supports not (-webkit-touch-callout: none) {
        background-attachment: fixed;
    }

    @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
    }
`;