import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../shared/Button';
import { Card, CardIcon, CardHeading, CardPricing, CardBody } from '../shared/Card';
import { Grid } from '../layout/Grid';

import { ReactComponent as CameraIcon } from '../../assets/icons/camera-icon.svg';
import { ReactComponent as DroneIcon } from '../../assets/icons/drone-icon.svg';

export default function PhotographyPricing() {
    return (
        <Grid cols={3} gap={1}>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><DroneIcon /></CardIcon>
                <CardHeading>Drone Photos</CardHeading>
                <CardPricing>$175</CardPricing>
                <CardBody>
                    <ul>
                        <li>Aerial photos taken with a high-end drone</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="1" padding="true" justify="true">
                <CardIcon><CameraIcon /></CardIcon>
                <CardHeading>Tier 1 Still Photos</CardHeading>
                <CardPricing>$225</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes up to 3200 square feet</li>
                        <li>Comes with FREE drone photos</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="1" padding="true" justify="true">
                <CardIcon><CameraIcon /></CardIcon>
                <CardHeading>Tier 2 Still Photos</CardHeading>
                <CardPricing>$325</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes up to 5000 square feet</li>
                        <li>Comes with FREE drone photos</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="1" padding="true" justify="true">
                <CardIcon><CameraIcon /></CardIcon>
                <CardHeading>Tier 3 Still Photos</CardHeading>
                <CardPricing>$425</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes up to 7000 square feet</li>
                        <li>Comes with FREE drone photos</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="1" padding="true" justify="true">
                <CardIcon><CameraIcon /></CardIcon>
                <CardHeading>Tier 4 Still Photos</CardHeading>
                <CardPricing>Quote</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes larger than 7000 square feet.</li>
                        <li>Comes with FREE drone photos.</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
        </Grid>
    );
}