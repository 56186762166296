import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../shared/Button';
import { Card, CardIcon, CardHeading, CardPricing, CardBody } from '../shared/Card';
import { Grid } from '../layout/Grid';

import { ReactComponent as MatterportIcon } from '../../assets/icons/matterport-icon.svg';

export default function MatterportPricing() {
    return (
        <Grid cols={3} gap={1}>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><MatterportIcon /></CardIcon>
                <CardHeading>Tier 1 Virtual Tour</CardHeading>
                <CardPricing>$200 - $250</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes up to 3200 square feet</li>
                        <li>Immersive virtual tour of the property</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><MatterportIcon /></CardIcon>
                <CardHeading>Tier 2 Virtual Tour</CardHeading>
                <CardPricing>$350 - $450</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes up to 5000 square feet</li>
                        <li>Immersive virtual tour of the property</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><MatterportIcon /></CardIcon>
                <CardHeading>Tier 3 Virtual Tour</CardHeading>
                <CardPricing>$550 - $650</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes up to 7000 square feet</li>
                        <li>Immersive virtual tour of the property</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
            <Card alignment="center" border="true" padding="true" justify="true">
                <CardIcon><MatterportIcon /></CardIcon>
                <CardHeading>Tier 4 Virtual Tour</CardHeading>
                <CardPricing>Quote</CardPricing>
                <CardBody>
                    <ul>
                        <li>For homes up larger than 7000 square feet</li>
                        <li>Immersive virtual tour of the property</li>
                    </ul>
                </CardBody>
                <Button as={Link} to="/contact" bg="primary" color="light" size="md">Contact Us</Button>
            </Card>
        </Grid>
    );
}