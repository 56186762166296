import styled from 'styled-components';

export const Card = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: ${props => props.alignment ? props.alignment : ''};
    justify-content: ${props => props.justify ? 'space-evenly' : ''};
    flex: 1;
    width: 100%;
    padding: ${props => props.padding ? 'calc(var(--gap) * 2)' : ''};
    border-radius: var(--border-radius);
    border: ${props => props.border ? "1px solid var(--gray-2)" : "none"};
`;

export const CardHeading = styled.p`
    font-family: 'Barlow Condensed', 'Montserrat', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: var(--font-weight-bold);
    margin-bottom: calc(var(--gap) * 0.5);
`;

export const CardSubheading = styled.p`
    font-size: 0.875rem;
`;

export const CardImg = styled.img`
    border-radius: var(--border-radius);
    margin-bottom: var(--gap);
`;

export const CardBody = styled.div`
    text-align: ${props => props.alignment ? props.alignment : 'left'};
    font-size: 0.875rem;

    ul {
        margin-bottom: var(--gap);
    }
`;

export const CardIcon = styled.svg`
    height: 4rem;
    width: 4rem;
    margin-bottom: var(--gap);
`;

export const CardPricing = styled.p`
    font-family: 'Barlow Condensed', 'Montserrat', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 3.5rem;
    font-weight: var(--font-weight-bold);
    margin: var(--gap) 0;

    @media (min-width: 48rem) {
        font-size: 3rem;
    }
`;