import styled from 'styled-components';

export const Heading = styled.div`
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: calc(var(--gap) * 2);
    border-bottom: 2px solid var(--dark);
`;

export const Subheading = styled.div`
    font-size: 1.75rem;
    text-align: center;
    text-transform: uppercase;
`;