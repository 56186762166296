import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../components/shared/Button';
import { Container } from '../components/layout/Container';
import { Display1 } from '../components/shared/Display';
import { Heading } from '../components/shared/Heading';
import { Section } from '../components/shared/Section';
import { SectionHeader } from '../components/shared/SectionHeader';

import ServiceCards from '../components/features/ServiceCards';
import PhotoGallery from '../components/features/PhotoGallery';

export default function Home() {
    return (
        <>
            <SectionHeader bg="https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/2301-n-winnifred-st/DJI_0127-min_ba6bep.webp">
                <Display1>TNT Visual Productions</Display1>
                <Button as={Link} to="/services" bg="primary" color="light" size="md">Book Us Now</Button>                 
            </SectionHeader>
            <Container>
                <Section>
                    <Heading>
                      <h2>About Us</h2>
                    </Heading>
                    <p>Established in the Seattle-Puget Sound area, TNT Visual Productions is here to give back to the communities that raised us by showcasing the beauty of each individual property as a potential new home to people from the 
                        surrounding area and afar. By providing photos, videos, aerial, and virtual tours, we are able to properly display each home and help keep the community strong with prospective newcomers. It is our goal to capture the 
                        true essence of each home to help streamline the madness that comes with home shopping. We thank you for choosing TNT Visual Productions and we look forward to a great relationship to help empower our communities together 
                        moving forward.
                    </p>  
                </Section>
                <Section>
                    <Heading>
                      <h2>Our Services</h2>
                    </Heading>
                    <ServiceCards />  
                </Section>
            </Container>
            <SectionHeader bg="https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/35114-13th-pl-sw/DJI_0086-min_ejb0ao.webp" />
            <Container>
                <Section>
                    <Heading>
                        <h2>Our Work</h2>
                    </Heading>
                    <PhotoGallery />
                </Section>
            </Container>
        </>
    );
}