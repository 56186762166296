import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --primary-light: #829456;
        --primary: #657343;
        --primary-dark: #4A5431;
        --secondary-light: #416E81;
        --secondary: #30515F;
        --secondary-dark: #1F343D;

        --success-light: #34E067;
        --success: #1EC24F;
        --success-dark: #17963D;
        --warning-light: #FCA555;
        --warning: #FB8B24;
        --warning-dark: #E67104;
        --error-light: #F36771;
        --error: #F03A47;
        --error-dark: #E31120;
        --info-light: #6BBDEA;
        --info: #3DA8E4;
        --info-dark: #1D91D2;

        --light: #FBFBF8;
        --gray: #E0E0E0;
        --gray-2: #C2C2C2;
        --gray-3: #A3A3A3;
        --gray-4: #8F8F8F;
        --gray-5: #7A7A7A;
        --gray-6: #666666;
        --gray-7: #474747;
        --gray-8: #333333;
        --dark: #1A1A1A;

        --gap: 1rem;
        --border-radius: 0.5rem;
        --font-size: 1rem;
        --font-weight-regular: 400;
        --font-weight-bold: 700;

        --length-xs: 1rem;
        --length-sm: 1.25rem;
        --length-md: 1.5rem;
        --length-lg: 1.75rem;
        --length-xl: 2rem;
        
        --screen-xs: 0;
        --screen-sm: 36rem;
        --screen-md: 48rem;
        --screen-lg: 62rem;
        --screen-xl: 120rem;

        --max-width-xs: 100%;
        --max-width-sm: 100%;
        --max-width-md: 90%;
        --max-width-lg: 56.25rem;
        --max-width-xl: 67.5rem;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    * {
        margin: 0;
        padding: 0;
    }

    body {
        font-family: 'Montserrat', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
        font-size: var(--font-size);
        font-weight: var(--font-weight-regular);
        --webkit-text-size-adjust: 100%;
        color: var(--dark);
        background: var(--light);
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Barlow Condensed', 'Montserrat', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
        font-weight: var(--font-weight-bold);
        line-height: 1.25;
    }

    a:active, a:focus, button:active, button:focus {
        outline: none;
        box-shadow: none;
        shadow: none;
    }

    ul, ol {
        margin-left: var(--gap);
        list-style-type: circle;
    }

    img {
        width: 100%;
        vertical-align: middle;
        border-style: none;
    }

    svg {
        height: var(--font-size);
        width: var(--font-size);
        fill: var(--dark);
    }
`;