import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Topbar } from './Topbar';
import { Footer } from './Footer';

export const TextLink = styled(Link)`
    color: var(--info);
    text-decoration: none;
    transition: all 0.25s linear;

    ${Topbar} &, ${Footer} & {
        font-family: 'Barlow Condensed', sans-serif;
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        color: inherit;
    }

    ${Topbar} & {
        font-size: calc(var(--font-size) * 2.5);
    }

    ${Footer} & {
        font-size: var(--font-size);
    }

    @media (min-width: 48rem) {
        ${Topbar} & {
            font-size: calc(var(--font-size) * 1.25);
        }
    }
`; 