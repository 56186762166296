import React, { useState, useEffect } from 'react';

import { Topbar, TopbarNav, TopbarMobileNav, TopbarNavItem, TopbarLogo } from '../shared/Topbar';
import { TextLink } from '../shared/TextLink';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';

export default function Navbar() {
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    
    const handleScroll = () => window.scrollY > 500 ? setScroll(false) : setScroll(true);

    const handleClick = () => setOpen(!open);

    return (
        <Topbar role="navigation" bg={scroll ? "transparent" : "var(--light)"} boxShadow={scroll ? "none" : "-4px 4px 8px var(--gray-7)"}>
            <TopbarLogo>
                <TextLink to="/">
                    <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/f_auto,q_auto,w_auto,dpr_auto/tnt-visual-productions/tnt-logo.png" alt="TNT Visual Productions Logo" />
                </TextLink>
            </TopbarLogo>
            <TopbarNav open={open}>
                <TopbarNavItem onClick={handleClick}>
                    <TextLink to="/">Home</TextLink>
                </TopbarNavItem>
                <TopbarNavItem onClick={handleClick}>
                    <TextLink to="/services">Services</TextLink>
                </TopbarNavItem>
                <TopbarNavItem onClick={handleClick}>
                    <TextLink to="/gallery">Gallery</TextLink>
                </TopbarNavItem>
                <TopbarNavItem onClick={handleClick}>
                    <TextLink to="/contact">Contact</TextLink>
                </TopbarNavItem>
            </TopbarNav>
            <TopbarMobileNav>
                { open ? <CloseIcon onClick={handleClick} /> : <MenuIcon onClick={handleClick} /> }
            </TopbarMobileNav>
        </Topbar>
    );
}