import React from 'react';

import { Container } from '../components/layout/Container';
import { Display1 } from '../components/shared/Display';
import { Section } from '../components/shared/Section';
import { SectionHeader } from '../components/shared/SectionHeader';

import PhotoGallery from '../components/features/PhotoGallery';

export default function Gallery() {
    return (
        <>
            <SectionHeader bg="https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/2301-n-winnifred-st/DJI_0138-min_icpvus.webp">
                <Display1>Our Work</Display1>
            </SectionHeader>
            <Container>
                <Section>
                    <PhotoGallery />
                </Section>
            </Container>
        </>
    );
}