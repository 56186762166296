import styled from 'styled-components';

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(var(--gap) * 4);
    background: var(--primary-light);
`;

export const FooterCopyright = styled.div`
    font-size: calc(var(--font-size) * 0.85);
    text-align: center;
`;

export const FooterNav = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--gap);

    @media (min-width: 48rem) {
        flex-direction: row;
        justify-content: space-evenly;
    }
`;

export const FooterNavItem = styled.div`
    margin: 0 calc(var(--gap) * 0.5);
    padding: calc(var(--gap) * 0.25);
    transition: all 0.25s linear;
    border-bottom: 2px solid transparent;
    &:hover {
        border-bottom: 2px solid var(--dark);
    }
`;

export const FooterCertifications = styled.div`
    display: flex;
    flex-direction: column;

    img {
        width: 240px;
        margin: var(--gap);
    }
`;