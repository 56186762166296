import React from 'react';

import { Card, CardHeading, CardBody, CardIcon } from '../shared/Card';
import { Grid } from '../layout/Grid';
import { SmallText } from '../shared/SmallText';

import { ReactComponent as CameraIcon } from '../../assets/icons/camera-icon.svg';
import { ReactComponent as DroneIcon } from '../../assets/icons/drone-icon.svg';
import { ReactComponent as MatterportIcon } from '../../assets/icons/matterport-icon.svg';
import { ReactComponent as VideoIcon } from '../../assets/icons/video-icon.svg';

export default function ServiceCards() {
    return (
        <Grid cols={4} gap={2}>
            <Card alignment="center">
                <CardIcon><CameraIcon /></CardIcon>
                <CardHeading>Still Photos</CardHeading>
                <CardBody alignment="center">
                    <SmallText>Stunning, professional quality photos that make your beautiful home pop.</SmallText>
                </CardBody>
            </Card>
            <Card alignment="center">
                <CardIcon><VideoIcon /></CardIcon>
                <CardHeading>Professional Videos</CardHeading>
                <CardBody alignment="center">
                    <SmallText>Captivating, cinematic videos that show off the beautiful interior of your property.</SmallText>
                </CardBody>
            </Card>
            <Card alignment="center">
                <CardIcon><DroneIcon /></CardIcon>
                <CardHeading>Drone Imaging</CardHeading>
                <CardBody alignment="center">
                    <SmallText>High-quality aerial photos and videos showcasing the listing from a unique POV.</SmallText>
                </CardBody>
            </Card>
            <Card alignment="center">
                <CardIcon><MatterportIcon /></CardIcon>
                <CardHeading>Virtual Tours</CardHeading>
                <CardBody alignment="center">
                    <SmallText>Immersive virtual experience that puts your audience inside your listing.</SmallText>
                </CardBody>
            </Card>
        </Grid>
    );
}