import React from 'react';

import { Container } from '../components/layout/Container';
import { Display1 } from '../components/shared/Display';
import { Heading } from '../components/shared/Heading';
import { Section } from '../components/shared/Section';
import { SectionHeader } from '../components/shared/SectionHeader';

import PhotographyPricing from '../components/features/PhotographyPricing';
import MatterportPricing from '../components/features/MatterportPricing';
import VideographyPricing from '../components/features/VideographyPricing';

export default function Services() {
    return (
        <>
            <SectionHeader bg="https://res.cloudinary.com/tnt-visual-productions/image/upload/q_auto,f_auto,w_2880,dpr_auto/15128-276th-pl-ne/DJI_0169-min_wvfz8m.webp">
                <Display1>Our Services</Display1>
            </SectionHeader>
            <Container>
                <Section>
                    <Heading>
                        <h2>Photography Pricing</h2>
                    </Heading>
                    <PhotographyPricing />
                </Section>
                <Section>
                    <Heading>
                        <h2>Videography Pricing</h2>
                    </Heading>
                    <VideographyPricing />
                </Section>
                <Section>
                    <Heading>
                        <h2>Virtual Tour Pricing</h2>
                    </Heading>
                    <MatterportPricing />
                </Section>
            </Container>
        </>
    );
}