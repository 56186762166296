import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import ScrollToTop from './hooks/ScrollToTop';
import { GlobalStyles } from './components/GlobalStyles';

import Navbar from './components/features/Navbar';
import CustomFooter from './components/features/CustomFooter';

import Home from './pages/Home';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';

const NoMatch = () => {
  let location = useLocation();

  return (
    <Redirect to="/" />
  );
}

export default function App() {
  return (
    <>
      <GlobalStyles />
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/services" component={Services} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/contact" component={Contact} />
        <Route path="*" component={NoMatch} />
      </Switch>
      <CustomFooter />
    </>
  );
}